import * as React from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import { FaPlus } from "react-icons/fa";
import { Link } from "gatsby";
import img1 from '@images/fotos/img1.jpg';
import img2 from '@images/fotos/img2.jpg';
import img3 from '@images/fotos/img3.jpg';
import adorno from '@images/adorno/adorno12.png';











// markup
const IndexPage = () => {
  return (
    <TemplatePage1
      title={"importance of the Quality on Latin America and The Caribbean"}
      adorno={<Adorno/>}
      content={(
        <>
          <HexaWrapper>
            <HexaRowPair>
              <Hexagon bgc={"var(--color1)"} >
                <TextZone>
                  We acumulate decades of slow advances in order to achieve a better attention quality in the region. The pandemic has revealed the inequity that increases the gaps.
                </TextZone>


              </Hexagon>
              <Hexagon bgc={"var(--color2)"}>
                <TextZone>
                  We have been innefective in generate enough evidence of the size of the problem, in the same way the development of new solutions.
                </TextZone>

              </Hexagon>
            </HexaRowPair>
            <HexaRowOdd>
              <Hexagon bgc={"var(--color3)"}>
                <TextZone>
                  The public policies incorporate in general many few scientific findings in its planning stage at the moment we start talking about attention quality.</TextZone>
              </Hexagon>
              <Hexagon bgc={"var(--color4)"}>
                <TextZone>
                  The report of the Lancet comission for the global health  in high quality of the Health Systems suggests that 300.000 deaths could be avoided anually applying intervention in improvement of LAC quality.
                  </TextZone>
              </Hexagon>
            </HexaRowOdd>
          </HexaWrapper>
          <TextHolder>
            <div>
            <p>
            QuEST LAC is making research pertinent to the region.
            </p>
            <Button to={"/actividades/publicaciones"}>
              See publications
            </Button>
            </div>
          </TextHolder>
        </>
      )}
    />
  )
};

export default IndexPage;

const HexaWrapper = styled.div`
position:relative;
width:100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
@media only screen and (min-width:930px){
  height: 550px;
}
`;


const HexaRowPair = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
width:300px;
flex-wrap: wrap;
@media only screen and (min-width:930px){
  flex-wrap: nowrap;
  justify-content: flex-end;
  transform: translateX(-135px);
  width:100%;
  height: 240px;
}


`;

const HexaRowOdd = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
width:300px;
@media only screen and (min-width:930px){
  flex-wrap: nowrap;
  width:100%;
  justify-content: flex-end;
  height: 240px;
}

`;


const TextZone = styled.div`
color: white;
width:100%;
height:100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
z-index: 99999;

text-shadow: 0px 0px 4px #FFFFFF;

span{
  font-size: 20px;
}


a{
  position:relative;
  display: flex;
  flex-direction: row;
  align-items: center;
justify-content: center;
text-align: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 10px;
  color: var(--tema4);
  padding: 1px;
}

:hover{
opacity: 1;
}

@media only screen and (min-width:768px){
  span{
    font-size: 70px;
  }
  a{
    font-size: 18px;
  }
}

`

const Hexagon = styled.div`
position: relative;
padding: 15px;
clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
background-size: cover;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
height: 311px;
min-height:311px;
width: 270px;
min-width:270px;


::before{
  content: "";
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height:100%;
  background-color: ${props => props.bgc ? props.bgc : ""};
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  background-image: url(${props => props.bg ? props.bg : ""});
  background-size: cover;
}

:hover{
  ::before{
  content: "";
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height:100%;
  background-color: ${props => props.bgc ? props.bgc : ""};
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  background-image: url(${props => props.bg ? props.bg : ""});
  background-size: cover;
}
}

`

const Adorno = styled.div`
position: absolute;
top: 2px;
right: -60px;
height:81%;
@media only screen and (min-width:900px){
  top: 10px;
height:93%;
width: 600px;
right: 770px;
background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
@media only screen and (min-width:1560px){
  top: -15px;
height:93%;
width: 600px;
right: 740px;
background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
`;

const Button = styled(Link)`
color: white;
background-color: var(--color2);
margin: 15px auto;
padding: 10px 15px;
`

const TextHolder = styled.div`
transform: translate(-10px);
padding-top:20px;
width: 100%;
display: flex;
flex-direction: column;
margin: auto;
@media only screen and (min-width:768px){
  align-items: flex-end;
}
align-items: center;
justify-content: center;

div{
  max-width:600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

p{
  margin: 15px;
  text-align: center;
}
margin: 15px;
font-size:1.5rem;
`